import { setModalData, setModalOpen } from "src/store/slices/modalSlice";
import { AppDispatch } from "src/store/store";
import { useDispatch } from "react-redux";
import { Icon, P } from "src/components";
import { useUser } from "src/store/slices/userSlice";
import { useCompany } from "src/store/slices/companySlice";
import cn from "classnames";
import {
  DataPointDomainCompaniesCompany,
  useGetMeQuery,
} from "src/store/serviceApi";
import { TranslatedUserRoleValues } from "src/models/Roles";
import { ReactComponent as SimpleIcon } from "../../../assets/icons/simplepay.svg";

interface Props {
  companyItem: DataPointDomainCompaniesCompany;
}

const CompanyListItem: React.FC<Props> = ({ companyItem }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { company, tenants } = useCompany();
  const { data: userData } = useGetMeQuery(
    {
      tenantId: company?.tenantId || "",
    },
    { refetchOnMountOrArgChange: true }
  );
  const handleOpenDeleteCompanyModal = () => {
    dispatch(setModalData(companyItem));
    dispatch(setModalOpen("delete-company-modal"));
  };

  const navigateToCustomerPortal = (
    route: "bankaccount" | "bank-integrations"
  ) => {
    const customerPortalUrl =
      (process.env.REACT_APP_CUSTOMERPORTAL_URL as string) + route;
    const tenantId = companyItem?.tenantId;
    const companyId = companyItem?.id;
    const companyName = encodeURIComponent(companyItem?.name ?? "");

    const url = `${customerPortalUrl}?tenantId=${tenantId}&companyId=${companyId}&companyName=${companyName}`;

    window?.open(url, "_blank");
  };

  return (
    <div
      className={cn(
        "flex flex-col items-start gap-4 self-stretch p-4 rounded-3xl border border-black/10",
        {
          "opacity-50 bg-gray-500/5": companyItem?.isDeleted,
        }
      )}
    >
      <div className="flex self-stretch justify-between items-center">
        <div className="flex items-start self-stretch flex-col gap-4">
          <div className="flex justify-between items-start self-stretch">
            <div className="items-center md:flex gap-2">
              <P className="!font-500">{companyItem.name ?? ""}</P>{" "}
              <P className="text-sm">
                {`(${
                  tenants?.find((c) => c.id === company?.tenantId)?.name ?? ""
                } Kulcs-Ügyfél)`}
              </P>
            </div>

            {/* {userData?.role === TranslatedUserRoleValues.Administrator && !companyItem?.isDeleted && (
          <Icon
            icon="bin"
            className="text-[#ff8780] cursor-pointer"
            onClick={handleOpenDeleteCompanyModal}
          />
        )} */}
          </div>
          <div className="flex items-start gap-2">
            <P className="!font-500 !leading-[20px] !text-black/60">Adószám</P>
            <P className="">{companyItem.taxNumber}</P>
          </div>
        </div>
        {userData?.role === TranslatedUserRoleValues.Administrator &&
          !companyItem?.isDeleted && (
            <div className="flex gap-1">
              <span className="md:w-14">
                <SimpleIcon
                  className="cursor-pointer"
                  height={32}
                  width={32}
                  onClick={() => navigateToCustomerPortal("bank-integrations")}
                />
              </span>
              <span className="md:w-14">
                <Icon
                  icon="bank-symbol"
                  className="cursor-pointer"
                  size={32}
                  onClick={() => navigateToCustomerPortal("bankaccount")}
                />
              </span>
            </div>
          )}
      </div>
    </div>
  );
};
export default CompanyListItem;
